.prices-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #1E1460;
    background-size: 115% auto;
    background-position-y: 80%;
    background-position-x: 50%;
    padding-top: 30px;
    padding-bottom: 30px;
    border-radius: 20px;
    margin-bottom: 20px;
}

.prices-rounds-container {
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.prices-rounds-container h4 {
    color: white;
    -webkit-text-stroke: 0.1px #F8C600;
    background-repeat: no-repeat;
    background-size: 100% 0.2em;
    background-position: 0 88%;
    width: fit-content;
}

.prices-rounds-container h4 span {
    background-color: #6D5EF7;
    color: #fff;
    background-repeat: no-repeat;
    background-size: 100% 0.2em;
    background-position: 0 88%;
    width: fit-content;
    padding: 5px;
    border-radius: 5px;
}

.kalao-container {
    background-color: #1868B7;
    padding-top: 1px;
    padding-bottom: 1px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.kalao-container h4 {
    color: white;
    letter-spacing: 0.05rem;
    margin-right: 10px;
    font-weight: 500;
}

.kalao-container img {
    height: 50px;
    width: auto;
}
