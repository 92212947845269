.marked-text {
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: .25em;
    display: inline-block;
    padding: 5px 2px 3px 6px; /*Top - Right - Bottom - Left*/
    position: relative;
    cursor: pointer;
    background-color: #F8C600;
}

.underline--magical {
    background-image: linear-gradient(120deg, #F8C600 0%, #F8C600 70%, #772784 100%);
    background-repeat: no-repeat;
    background-size: 100% 0.2em;
    background-position: 0 88%;
    transition: background-size 0.25s ease-in;
    font-family: "Titan One", san-serif, serif;
    font-weight: normal;
    -webkit-text-stroke: 1px #772784;
}

.underline--magical:hover {
    background-size: 100% 88%;
}

.title-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
}

.subtitle-mint {
    color: #fff;
    margin-top: -25px;
    margin-left: 180px;
    text-align: center;
    font-weight: 500;
}

.text-round {
    color: #F8C600;
    -webkit-text-stroke: 0.1px #772784;
    font-family: "Titan One", san-serif, serif;
}
