.mint-item-img {
    width: auto;
    height: 280px;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    z-index: 2;
}

.mint-grid-container {
    z-index: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 20px;
    margin-bottom: 20px;
    position: relative;
}

.clouds-bg {
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #3D0B6D;;
    background-size: 100% auto;
    border-radius: 20px;
    box-shadow:
            inset 0px 11px 18px -10px #CCCCCC33,
            inset 0px -11px 18px -10px #CCCCCC33;
}

.clouds-1 {
    background-position: 0 18%;
}

.clouds-2 {
    background-position: 0 27%;
}

.clouds-3 {
    background-position: 0 63%;
}

.clouds-4 {
    background-position: 0 70%;
}

.mint-item-button {
    border: 1px solid #fff;
    background-color: #5547C8 !important;
    width: 30px;
}

.btn-call-mint {
    font-family: "Titan One", san-serif, serif !important;
    font-weight: normal;
    font-size: 1.8rem !important;
    height: 60px;
    color: white !important;
    transition: all 0.3s ease-in;
    box-shadow: #FFF 0 0 0 2px !important;
    border-radius: 10px !important;
}

.btn-call-mint:hover.green {
    background-color: #FFF !important;
    color: #02732A !important;
    transition: all 0.3s ease-in-out;
    box-shadow: #02732A 0px 0px 0px 2px !important;
}

.btn-call-mint:hover.blue {
    background-color: #FFF !important;
    color: #0339A6 !important;
    transition: all 0.3s ease-in-out;
    box-shadow: #0339A6 0px 0px 0px 2px !important;
}

.btn-call-mint:hover.purple {
    background-color: #FFF !important;
    color: #A6059B !important;
    transition: all 0.3s ease-in-out;
    box-shadow: #A6059B 0px 0px 0px 2px !important;
}

.btn-call-mint:hover.gold {
    background-color: #FFF !important;
    color: #F2C53D !important;
    transition: all 0.3s ease-in-out;
    box-shadow: #F2C53D 0px 0px 0px 2px !important;
}

.marked-text.green {
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: .25em;
    display: inline-block;
    padding: 5px 2px 3px 6px; /*Top - Right - Bottom - Left*/
    position: relative;
    cursor: pointer;
    background-color: #02732A;
}

.marked-text.blue {
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: .25em;
    display: inline-block;
    padding: 5px 2px 3px 6px; /*Top - Right - Bottom - Left*/
    position: relative;
    cursor: pointer;
    background-color: #0339A6;
}

.marked-text.purple {
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: .25em;
    display: inline-block;
    padding: 5px 2px 3px 6px; /*Top - Right - Bottom - Left*/
    position: relative;
    cursor: pointer;
    background-color: #A6059B;
}

.marked-text.gold {
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: .25em;
    display: inline-block;
    padding: 5px 2px 3px 6px; /*Top - Right - Bottom - Left*/
    position: relative;
    cursor: pointer;
    background-color: #F2C53D;
}
